<template>
  <div class="content">
    <div class="nav">
      <header-base :sear="'hide'"></header-base>
    </div>
    <div class="menu">
      <div class="title_nav">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
          v-for="(i,k) in commodityName" :key="k"
          :label="i.label"
          :name="i.name"
          >
            <component :is="activeName"
            @auctionlistData="()=>{auctionListShow}">
            </component>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="menu_body">
        <div class="alltitle">
          <ul class="all_title_ul">
            <li class="all_title_li"
            v-for="i in alltitle" :key="i">
              {{i}}
            </li>
          </ul>
        </div>

        <div class="order_body"
        v-for="(i,k) in ListData" :key="k">
          <ul class="order_body_ul">
            <li class="order_body_li body_num1">
              <div class="namebody">
                <img :src="i.smsAuctionProduct.productPic" alt="">
                <div class="name_time">
                  <p>{{i.smsAuctionProduct.productName}}</p>
                  <span>{{i.createTime}}</span>
                </div>
              </div>
            </li>
            <li class="order_body_li body_num2">
              <span class="li2_span_kg">{{i.num}}{{i.smsAuctionProduct.unit}}</span>
            </li>
            <li class="order_body_li body_num3">
              <span class="money_span">￥{{i.price}}</span>
            </li>
            <li class="order_body_li body_num4">
              <span class="status_name">{{
                i.status == 0
                ? "出价中"
                : i.status == 1
                ? "已成交"
                : "未成交"}}</span>
            </li>
            <li class="order_body_li body_num5">
              <span class="order_paymoney" @click="auctionBtn1(i)">查看</span>
              <!-- <span class="order_remove"  @click="auctionBtn2(i)"
              v-if="i.status===0||i.status===2?true:false">支付</span> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="showfooter" v-if="totalnum<=pagesize?false:true">
        <el-pagination
          v-model:currentPage="currentPage"
          v-model:page-size="pagesize"
          layout="prev, pager, next"
          :total="totalnum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>

    <div class="divfooter">
        <Footer/>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import Footer from '../../index/Footer.vue'
import http from '../../../api/http'
import HeaderBase from '../../../components/HeaderBase.vue'
import { setStore } from '../../../tools/storage'

const memberGetRushHistory = (data) => {
  return http.get('mall-buyer/auction/memberGetRushHistory', data)
}
export default {
  components: { Footer, HeaderBase },
  name: 'MyAuctionBody',
  data () {
    return {
      alltitle: [this.$t('m.auction.commodity_information'), this.$t('m.auction.quantity'), this.$t('m.auction.amount'), this.$t('m.auction.state'), this.$t('m.auction.operate')],
      commodityName: [
        { label: this.$t('m.auction.My_bid'), name: 'myAllAuction' },
        { label: this.$t('m.auction.In_the_auction'), name: 'competeIn' },
        { label: this.$t('m.auction.Successful_auction'), name: 'purchase' },
        { label: this.$t('m.auction.Failed_auction'), name: 'failure' }
      ], // 竞拍状态标题内容
      activeName: 'myAllAuction', // 当前选择的竞拍项
      actCName: '我的竞拍', // 当前选择的竞拍项

      // 存放总数据个数
      totalnum: ref(''),
      // 存放一页显示个数
      pagesize: ref(10),
      // 默认展示页
      currentPage: ref(1),
      ListData: reactive([]), // 存放当前选择页的数据
      auctionDataS: reactive([]) // 默认存储所有的
    }
  },
  methods: {
    // 查询数据
    async initData (num, status) {
      const loading = this.$loading({
        lock: true,
        text: '加载中..'
      })
      const data = {
        type: '',
        section: '',
        pageSize: this.pagesize,
        pageNum: num || this.currentPage,
        status: status || ''
      }
      try {
        const res = await memberGetRushHistory(data)
        // console.log(res.data.data)
        this.totalnum = res.data.data.total // 总个数
        this.auctionDataS = res.data.data.records
        this.ListData = this.auctionDataS
        // this.auctionListShow(name)
        loading.close()
      } catch (error) {
        console.log(error)
        loading.close()
      }
    },
    // 点击切换的函数
    handleClick (tab, event) {
      // console.log(tab)
      this.currentPage = 1
      this.auctionListShow(tab.props.name)
    },
    auctionListShow (name, num) {
      // console.log(name)
      // console.log(name)
      // this.actCName = name.label
      switch (name) {
        case 'myAllAuction': this.toShowList('', num)
          break
        case 'competeIn': this.toShowList('0', num)
          break
        case 'purchase': this.toShowList('1', num)
          break
        case 'failure': this.toShowList('2', num)
          break
        default:
          break
      }
      // this.totalnum = this.ListData.length
      // console.log(this.ListData)
      // emitter.emit('auctionDataTo', { name: name, list: this.auctionData })
    },
    // 处理不同状态的切换展示效果
    toShowList (name, num) {
      this.initData(num, name)
      // this.ListData = []
    },
    auctionBtn1 (item) { // 查看详情
      setStore('AuctionMsg', item)
      this.$router.push('/auction/myauction/acdetails')
      console.log(item)
    },
    auctionBtn2 (item) {
      console.log(item)
    },
    // 分页
    handleCurrentChange (val) {
      // this.initData(val, this.activeName)
      this.auctionListShow(this.activeName, val)
      window.document.documentElement.scrollTop = 0
    }
  },
  mounted () {
    this.initData()
  },
  setup () {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    return {
      handleSizeChange
    }
  }
}
</script>

<style  lang="scss">
@import '../../../style/viriables.scss';

.content{
  display: flex;
  flex-flow: column;
  width: 100%;
  // background: $divbgColor;
  background: #F2EFED;
  .nav{
    // height: 30px;
    width: 100%;
    background: #ffffff;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .menu{
    // flex: 1;
    flex-shrink: 0;
    min-height: 645px;
    background: #FFFFFF;
    width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
    .title_nav{
      height: 70px;
      padding: 0 20px;
      display: flex;
      align-items: flex-end;
      // margin-bottom: 10px;
      .el-tabs{
        .el-tabs__header{
          margin: 0;
          .el-tabs__nav-wrap{
            .el-tabs__nav-scroll{
              .el-tabs__nav{
                .el-tabs__item{
                  height: 50px;
                  font-size: 20px;
                  font-weight: bold;
                }
                .el-tabs__active-bar{
                  background: #C6824E;
                }
                .is-active{
                  color: #C6824E;
                }
                .el-tabs__item:hover{
                  color: #c49979;
                }
              }
            }
          }
          .el-tabs__nav-wrap::after{
            height: 0;
          }
        }
      }
    }
    .menu_body{
      padding: 20px;
      .alltitle{
        width: 100%;
        background: #F7F7F7;
        border: 1px solid #EAEAEA;
        // margin-bottom: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        .all_title_ul{
          height: 50px;
          padding: 0 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .all_title_li:first-child{
            width: 520px;
          }
          .all_title_li:not(:first-child){
            flex: 1;
            text-align: center;
            border-left: 1px solid #EAEAEA;
          }
          .all_title_li{
            height: 100%;
            line-height: 50px;
            color: #999999;
          }
        }
      }

      .order_body{
        width: 100%;
        height: 120px;
        border: 1px solid #EAEAEA;
        border-top: 0;
        // background: hotpink;
        .order_body_ul{
          display: flex;
          align-items: center;
          padding: 0 20px;
          border-top: 0;
          width: 100%;
          box-sizing: border-box;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          .order_body_li{
            height: 120px;
            box-sizing: border-box;
            padding: 20px;
          }
          .order_body_li:first-child{
            width: 520px;
            .namebody{
              display: flex;
              img{
                height: 80px;
                width: 80px;
              }
              .name_time{
                margin-left: 10px;
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                p{
                  width: 300px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                span{
                  color: #999999;
                }
              }
            }
          }
          .order_body_li:nth-child(5){
            // flex-flow: column;
            span{
              display: block;
              width: 40px;
              color: #C6824E;
              margin: 0 auto;
              cursor: pointer;
            }
            span,button:nth-child(1){
              margin-bottom: 20px;
            }
          }
          .order_body_li:not(:first-child){
            flex: 1;
            border-left: 1px solid #EAEAEA;
            text-align: center;
          }
        }
      }
    }
  }
  .showfooter{
    height: 100px;
    // background: red;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pagination{
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
  .divfooter{
    background: #280E01;
  }
}
</style>
